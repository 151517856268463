import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import VideoPlayerDetailComponent from '@components/video-library/video-player-detail.component'
import EventPresentersComponent from '@components/archived-event-card/event-presenters.component'
import { trackGa } from '@components/GoogleTracking'

import './archived-event-card.component.scss'


class ArchivedEventCard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isContentHidden: true
    }

    this.expandibleRef = React.createRef()
  }
  render() {
    return (
      <div className="archived-card" ref={this.expandibleRef}>
        <div className="archived-card-top">
          <div className="card-top-left">
            <h2 className="archived-card-header ">{this.props.archivedCardContent.title1}</h2>
            <div className="video-container-small" role="image" aria-label={this.props.archivedCardContent.imageAlt}>
              <VideoPlayerDetailComponent
                video={this.props.archivedCardContent.video}
                videoClass={this.props.videoClass.description}
                videoTitleColon={this.props.videoTitleColon.colon}
                location={this.props.location}
              />
            </div>
            <h4 className="event-date">{this.props.archivedCardContent.date}</h4>
            <p className="body-copy ">{this.props.archivedCardContent.content1}</p>
          </div>
          <div className="video-container-big" role="image" aria-label={this.props.archivedCardContent.imageAlt}>
            <VideoPlayerDetailComponent
              video={this.props.archivedCardContent.video}
              videoClass={this.props.videoClass.description}
              videoTitleColon={this.props.videoTitleColon.colon}
            />
          </div>
        </div>
        <div className={this.isExpanded(this.state.isContentHidden)}>
          <div className="bottom-content">
            <div className="bottom-content-left">
              <h3 className="secondary-title">{this.props.archivedCardContent.title2}</h3>
              <p className="body-copy">{this.props.archivedCardContent.content2}</p>
            </div>
            <div className="bottom-content-right">
              <h3 className="presenter-title">Faculty Presenters:</h3>
              <div className="presenters-wrapper">
                <EventPresentersComponent presenterContent={this.props.archivedCardContent.presenter1} />
                <EventPresentersComponent presenterContent={this.props.archivedCardContent.presenter2} />
                <div className={this.props.archivedCardContent.cardClass}>
                  <EventPresentersComponent presenterContent={this.props.archivedCardContent.presenter3} />
                </div>
              </div>
            </div>
          </div>
          <div className="collapse-link " onClick={() => this.toggleExpansion()}>
            <FontAwesomeIcon icon="compress" />
            close details
          </div>
        </div>
        <div
          htmlFor="expandible"
          className={this.isClosed(this.state.isContentHidden)}
          onClick={() => {
            this.toggleExpansion()
            trackGa(this.props.lookDeeperTracking)
          }}
        >
          Look Deeper
          <FontAwesomeIcon icon="expand" />
        </div>
      </div>
    )
  }

  isExpanded(isHidden) {
    return isHidden ? 'collapsed' : 'expanded'
  }

  isClosed(isHidden) {
    return isHidden ? 'look-deeper-card-expand' : 'look-deeper-card-hidden'
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.isContentHidden && !prevState.isContentHidden) {
      window.scrollTo(window.scrollX, this.expandibleRef.current.offsetTop - 100)
    }
  }

  toggleExpansion() {
    this.setState({ isContentHidden: !this.state.isContentHidden })
  }
}

ArchivedEventCard.propTypes = {
  archivedCardContent: PropTypes.object.isRequired,
  videoClass: PropTypes.object.isRequired,
  videoTitleColon: PropTypes.object.optional,
  lookDeeperTracking: PropTypes.string.optional
}

export default ArchivedEventCard
