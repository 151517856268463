import React from 'react'
import PropTypes from 'prop-types'

import './event-presenters.component.scss'

const EventPresentersComponent = props => {
  return (
    <div className="presenter-component-wrapper">
      <div className="presenter-image-container">
        <img src={props.presenterContent.image} className="presenter-image" alt={props.presenterContent.alt} />
      </div>
      <div className="presenter-about">
        <div className="presenter-name">{props.presenterContent.name}</div>
        <div className="presenter-about-content">{props.presenterContent.about1}</div>
        <div className="presenter-about-content">{props.presenterContent.about2}</div>
        <div className="presenter-about-content">{props.presenterContent.about3}</div>
        <div className="presenter-about-content">{props.presenterContent.city}</div>
      </div>
    </div>
  )
}

EventPresentersComponent.propTypes = {
  presenterContent: PropTypes.object.isRequired
}

export default EventPresentersComponent
