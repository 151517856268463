import roth from '@assets/images/thomas-roth-phd.jpg'
import jonathan from '@assets/images/jonathan-r-l-schwartz-md.jpg'
import karl from '@assets/images/karl-doghramji-md.jpg'

export const presenters = {
  thomasRoth: {
    image: roth,
    alt: 'Thomas Roth, PhD',
    name: 'Thomas Roth, PhD',
    about1: 'Director, Sleep Disorders and Research Center',
    about2: 'Henry Ford Hospital',
    city: 'Detroit, MI'
  },
  jonathanSchwartz: {
    image: jonathan,
    alt: 'Jonathan R. L. Schwartz, MD',
    name: 'Jonathan R. L. Schwartz, MD',
    about1: 'Medical Director, Integris Sleep Disorders Center of Oklahoma',
    about2: 'Clinical Professor, Department of Medicine',
    about3: 'University of Oklahoma Health Sciences Center',
    city: 'Oklahoma City, OK'
  },
  karlDoghramji: {
    image: karl,
    alt: 'Karl Doghramji, MD',
    name: 'Karl Doghramji, MD',
    about1: 'Professor of Psychiatry, Neurology, and Medicine',
    about2: 'Medical Director, Jefferson Sleep Disorders Center',
    about3: 'Thomas Jefferson University',
    city: 'Philadelphia, PA'
  }
}
